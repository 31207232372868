import { App } from '@/app.models';
import axios, { AxiosResponse } from 'axios';

export default class AuthService {
    constructor(private readonly config: App.AuthConfig) {}

    exchangeCodeForAuthToken(code: string): Promise<Auth.UserTokens> {
        const params = new URLSearchParams();
        params.append('grant_type', 'authorization_code');
        params.append('redirect_uri', this.config.redirectUri);
        params.append('client_id', this.config.clientId);
        params.append('code', code);

        const axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };

        return axios
            .post(
                `${this.config.baseUrl}${this.config.tokenEndpoint}`,
                params,
                axiosConfig
            )
            .then((r: AxiosResponse<Auth.UserTokens>) => {
                return r.data as Auth.UserTokens;
            });
    }

    exchangeRefreshTokenForAuthToken(
        refreshToken: string
    ): Promise<Auth.UserTokens> {
        const params = new URLSearchParams();
        params.append('grant_type', 'refresh_token');
        params.append('client_id', this.config.clientId);
        params.append('refresh_token', refreshToken);

        const axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };

        return axios
            .post(
                `${this.config.baseUrl}${this.config.tokenEndpoint}`,
                params,
                axiosConfig
            )
            .then((r: AxiosResponse<Auth.UserTokens>) => {
                return r.data as Auth.UserTokens;
            });
    }

    getPublicKeys(): Promise<Auth.PublicKey[]> {
        return axios
            .get(this.config.cognitoIssuer + '/.well-known/jwks.json')
            .then((r: AxiosResponse<Auth.PublicKey[]>) => r.data);
    }
}
